@import '../bootstrap-utils';

// .bulletin-back-home {
//     text-align: center;
//     margin-bottom: 40px;

//     &-button {
//         display: inline-block;
//         text-decoration: none;
//         padding: 20px 40px;
//         background-color: black;
//         font-weight: bold;
//         color: white;
//         border-radius: 100px;
//         font-size: 30px;
//     }
// }

.events-container {
    padding-top: 40px;
}

.upcoming-event {
    margin: -50px 0 50px;

    @include media-breakpoint-down(md) {
        margin: 0 0 20px;
    }
}

.events-content {
    background: #ffeacf;
    border-radius: 55px;
    padding: 20px 20px 40px;
    margin-bottom: 40px;
    box-shadow: 0px 20px 15px rgb(110, 110, 110);

    &-scroll {
        height: 60vh;
        overflow: auto;
        padding: 0 1rem;

        &-wrapper {
            background: #f3dbc3;
            padding: 0px 10px;
            margin: 20px 0;
            border-radius: 10px;
            box-shadow: 1px 2px 6px inset #e8c39e;
            border: 1px solid #fff6ed;
        }
    }
}

.events-actions {
    text-align: center;
    margin-top: 20px;

    &-button {
        display: inline-block;
        text-decoration: none;
        padding: 10px 40px;
        background-color: black;
        font-weight: bold;
        color: white;
        border-radius: 100px;
        font-size: 18px;
        border: 2px solid black;
        transition: 0.5s;

        &:hover {
            border: 2px solid #69F;
            color: #69F;
            transform: scale(1.1);
            box-shadow: 0 20px 40px rgb(0, 0, 0);
        }
    }
}

