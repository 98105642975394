@import './bootstrap-utils';

.button-back {
    display: inline-block;
    text-decoration: none;
    padding: 5px 30px;
    background-color: black;
    font-weight: thin;
    color: white;
    border-radius: 100px;
    font-size: 2rem;
    border: 2px solid black;
    transition: 0.5s;

        &:hover {
            border: 2px solid #69F;
            color: #69F;
            transform: scale(1.1);
            box-shadow: 0 20px 40px rgb(0, 0, 0);
        }
}

.church-hours-event-info{
    justify-content: end;
    text-align: center;
    margin: 5px 5px 20px;

}

.info-page {
    padding: 40px 0;

    .container {
        border-radius: 15px;
    }

    .event-title{
        text-align: center;
        margin-right: 20px;

        @include media-breakpoint-down(xl) {
            overflow: visible;
            white-space: initial;
            text-align: center;
        }
    }

    &__image {
        background: white no-repeat center;
        border-radius: 10px;
        border: 10px solid #CAF9FF;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        aspect-ratio: 1/1;
            // @include media-breakpoint-down(md) {
            //     aspect-ratio: initial;
            // }
        }

    &-container {
        width: 50%;
        margin-top: 50px;
        padding-left: 100px;

        @include media-breakpoint-down(xl) {
            width: auto;
            margin-top: 50px;
            padding: 0 25px;
        }
    }
    
    .time-info {
        font-weight: 400;
    }

    &__content {
        
        // margin-right: 20px;
        background: #D9D9D9;

        h3 {
            font-weight: normal;
            font-size: 1.3em;
        }
        
        ul {
            padding: 0;
            list-style: none;
        }
    
        h1, p, ul {
            font-weight: 800;
            font-size: 1.6em;
            margin-bottom: 60px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}