@import './bootstrap-utils';

.footer {
    color: #fff;
    text-align: left;
    background-color: #1A1919;
    padding: 40px 0 20px;

    &-top {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }

    &-contact-info {
        max-width: 250px;
    }

    &-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        &-item {
            color:#fff;
            text-decoration: none;
            font-weight: bold;
            background-color: transparent;
            border: 0;
            padding: 0;
            transition: all 0.3s ease-in-out; 

            &:hover {
                color: grey;
                transform: translateX(5px) !important;
            }
        }

        @include media-breakpoint-down(md) {
            align-items: center;
            gap: 15px;
        }
    }

    &-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        height: 40px;
        
        p {
            margin: 0;
        }
    }

    .admin-facebook-controller {
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: space-between;

        .btn-primary {

            @include media-breakpoint-down(md) {
                width: 250px;
            }
        }

        @include media-breakpoint-down(md) {
            display: block;
            margin: 20px 0;
            text-align: center;

        }
    }

    .fa-facebook {
        font-size: 16px;
        text-align: center;
        text-decoration: none;
        background: #3B5998;
        color: white;
        width: 40px;
        height: 40px;
        padding: 12px;
        display: block;
        transform: translateY(40px);

        &:hover {
            opacity: 0.7;
            color: white;
        }

        @include media-breakpoint-down(md) {
            transform: initial;
            margin: 20px auto;
        }
    }

    // padding-bottom: 120px;
    @supports (-webkit-touch-callout: none) {
        padding-bottom: 250px;
    }
}