.video-container {
    position: relative;
    padding: 0;
    overflow: hidden;

    video {
        /*height: 160%;;
        position: absolute;
        top: 0;*/
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    main {
        position: relative;
    }
}