.grid-2-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

form.modal-form {
    background: transparent;
    box-shadow: none;
    margin: 0;
    text-align: left;
    padding: 5px;

    input[type="text"] {
        width: 100%;
        margin-bottom: 10px;
    }
}

.modal-body {
    &-heading {
        color: white;
        font-weight: bold;
        margin-bottom: 20px;
    }
}
