html{
  background-color: rgb(60, 43, 35);
}

body {
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
  overflow-x: hidden;
  max-width: 100%;
}

ul {
  padding-left: 0;
}

/* #f6f9fc - I like this color */

/* //////////////////
admin-navbar (header) starts
////////////////// */

.header{
  min-height: 210px;
  text-align: center;
}

.who-input {
  width: 350px;
}

.what-input {
  width: 350px;
}


.name-date-list li{
  display: inline-flex;
  text-decoration-line: none;
}

.name{
  width: 300px;
}

.date{
  width: 300px;
}



/* title for about page */
.about-page-title {
  font-size: 45px;
  font-family: 'Inter', sans-serif;
  font-variant: small-caps;
}

div .navbar {
  background-color: rgb(60, 43, 35);
  width: 100%;
  min-height: 220px;
  display:block;
}

div .navbar ul li a.main{
  display: inline-flex;
  flex-wrap : wrap;
  padding-left: 20px;
}

.non-admin-nav-container li a.main {
  color: white;
  font-weight: regular;

}

.non-admin-nav-links{
  display: inline-flex;
  margin-bottom: 0px;
  list-style-type: none;
  padding-left: 55%;
  font-size: 12px;
}

/* //////////////////
 image for cross
////////////////////*/
.cross {
  padding-top: 10px;
}


a.dropdownitem {
  display: block;
  padding-left: 10px;

}

a.dropdownitem:hover {
  color: gray;

}

.navbar-banner-container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -15px);
  background-color: black;
  padding-top: 0px;
  display: block;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid white;
  width: 300px;
  z-index: 5;
}

h3.navbar-banner {
  border-radius: 100px;
  padding: 9px;
  font-size: 12px;
  color: white;
  font-weight: regular;
  margin: 0;
}

.dropdown-menu{ 
  background-color: white; 
}

.dropdown-item a{
  color: black;
}


/* Would like to set up parallax for the backgrounds */


.subscriptions-page-overlay {
  background-color: white;
  height: 2800px;
}

.bolder {
  font-weight: 800;
}

.header, .footer {
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .parallax-container {
    will-change: initial !important;
    transform: none !important;
  }
}