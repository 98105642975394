@import '../bootstrap-utils';

.back-home-button{
    text-align: center;

    &-bordered {
        display: inline-block;
        text-decoration: none;
        padding: 20px 40px;
        background-color: black;
        font-weight: bold;
        color: white;
        border-radius: 100px;
        font-size: 30px;
        border: 2px solid black;
        transition: 0.5s;

        &:hover {
            border: 2px solid #69F;
            color: #69F;
            transform: scale(1.1);
            box-shadow: 0 20px 40px rgb(0, 0, 0);
        }
    }
}