
// .About-page-background {
//     --bg-image: url(images/StPaulBaptistChurch.JPG);
//     --bg-image-opacity: .25;
//     height: 3000px;
  
//     position: relative;
//     isolation: isolate;
// }
  
//   .About-page-background::after {
//     content: '';
    
//     position: absolute;
//     background-position: -1924px -86px;
//     z-index: -1;
//     inset: 0;
//     opacity: var(--bg-image-opacity);
//     background-image: var(--bg-image);
//     background-size: 4910.3px 3265px;
//     background-repeat: no-repeat;
//     height: 3000px;
// }

@import './bootstrap-utils';

.about-page-background {
    position: static !important;
    background: rgba(255, 255, 255, 0.85);
    padding: 50px 0;

    &-container {
        background-position: 57% -75px !important;
    }

    .about-page{

        section {
            max-width: 460px;
            margin-right: auto;
            margin-left: auto;
            margin-top: 100px;

            &:last-of-type {
                margin-bottom: 80px;
            }

            &.section-large {
                max-width: 666px;
            }
        }

        .about{
            text-align: center;
            padding-top: 50px;
        }

        .about-page-title{
            margin-top: 20px;
            text-align: center;
        }

        .welcome-container{
            margin-top: 100px;
            text-align: center;

            .welcome{
                font-weight: bold;
                font-size: 30px;
            }
            
            .welcome-message{
                margin-top: 20px;

                @include media-breakpoint-down(md) {
                    padding: 30px;
                }
            }
        }

        .our-mission-message{
            text-align: center;

            @include media-breakpoint-down(md) {
                padding: 30px;
            }
        }

        .section-title{
            font-weight: bold;
            font-size: 30px;
            text-align: center;
            padding-top: 40px;
        }

        .prayer-request-message{
            text-align: center;

            @include media-breakpoint-down(md) {
                padding: 30px;
            }
        }

        .prayer-request {
            text-align: center;
            margin-bottom: 40px;

            
            &-button {
            display: inline-block;
            text-decoration: none;
            padding: 5px 10px;
            background-color: black;
            font-weight: thin;
            color: white;
            border-radius: 100px;
            font-size: 14px;
            border: 2px solid black;
            transition: 0.5s;

                &:hover {
                    border: 2px solid #69F;
                    color: #69F;
                    transform: scale(1.1);
                    box-shadow: 0 20px 40px rgb(0, 0, 0);
                }
            }
        }

        .history-title{
            margin-bottom: 8px;
        }
        
        .history-message{
            @include media-breakpoint-down(md) {
                padding: 30px;
            }
        }

        

    }
}

/* unorganized list for pictures */
.pastor-info-container {
    padding: 50px 0 120px;

    li {
        position: relative;
        
        h5 {
            position: absolute;
            top: calc(100% + 20px);
            width: 100%;
        }
    }
    
    .pastor-pictures {
        // position: relative;
        // list-style-type: none;
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
        // justify-content: center;
        // gap: 40px;
        // padding: 0;
        // max-width: 780px;
        // margin: 0 auto;

        position: relative;
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-content: center;
        gap: 40px;
        padding: 0;
        max-width: 400px;
        margin: 0px auto;

        &::before {
            content: '';
            display: block;
            position: absolute;
            background: white;
            height: 40%;
            top: 30%;
            box-shadow: 0 5px 10px #999;
            width: 5000px; // An addition container would be needed to fix this!
            left: -2000px; // same
        }
        
        .pastor-picture {

            width: 100%;
            aspect-ratio: 1/1;
            border-radius: 50%;
            border: 4px solid white;
            background-size: cover;
            background-position: center;
            box-shadow: 0 5px 10px #999;
    
            // &.pastor-brown {
            //     background-position: top center;
            // }

            &.pastor-bullock {
                background-position: 25% top;
                background-size: 170%;
            }
        }
    }
}