@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

//colors
$primary: #4E372B;
$pink: #FFC9F6;

// --bs-modal-border-radius

// modal
$modal-content-border-radius: 10px;

// inputs
$input-border-radius: 0;
$form-label-font-size: 18px;
$form-label-font-weight: bold;
$form-select-border-radius: 0;

$custom-theme-colors:map-merge($theme-colors, (
  "pink": $pink
));

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
