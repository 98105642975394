@import '../bootstrap-utils';

.honor-doc {
    border-radius: 20px;
    display: block;
    overflow: hidden;
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center;
    margin-bottom: 30px;
    color: #000;
    text-decoration: none;
    position: relative;
    background: black;
    border-radius: 20px;

    &-outer {
        padding-top: 150%;
        position: relative;
    }

    &-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &-media {
        &-container {
            height: 70%;
            border-radius: 0 0 20px 20px;
            overflow: hidden;
            
            &.media-image {
                height: 65%;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                margin: 20px 20px;
            }
            
            .media-embed {
                width: 100%;
                height: 100%;
                margin-bottom: 0;
                object-fit: cover;
            }

            @include media-breakpoint-up(xl) {
                height: 60%;
            }
        }
    }

    &-infos {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        height: 30%;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(xl) {
            height: 40%;
        }

        &-inner {
            position: relative;
            border: 10px solid black;
            background-color: white;
            border-radius: 20px;
            flex-grow: 1;
            overflow: hidden;
            padding: 15px 20px;

            > p {
                height: 100%;
                overflow: auto;
                margin: 0 0 5px;
            }
        }

        &-click-to-open {
            display: flex;
            gap: 5px;
            align-items: center;
            flex-direction: column;
            padding-bottom: 15px;

            a {
                color: black;
                background: white;
                display: inline-block;
                padding: 3px 8px;
                border-radius: 15px;
                text-decoration: none;
                line-height: 20px;
            }
        }
        
    }

    &-controls {
        display:none;
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        z-index: 2;

        .honor-doc:hover & {
            display:flex;
            padding-top: 5px;
            justify-content: space-between;
        }
    }

    &-control {
        border: 0;
        background-color: transparent;
        border-radius: 100px;
        width: 50px;  
        transition: all 0.3s ease-in-out; 
        opacity: 50%;
        
        &:hover{
            opacity: 100%;
            transform: translateY(-2px);
            transform: scale(1.2);
        }

        img {
            max-width: 100%;
        }
        
    }
}