@import './bootstrap-utils';

.profile-page-background {
    background-image: url(images/tree-background-red.jpeg);
    background-position: center center;
    padding: 150px 0;
}

.profile-info {
    font-weight: bold;

    &-square {
        max-width: 600px;
        width: 90%;
        background: #D9D9D9;
        border: 15px solid white;
        border-radius: 100px;
        padding: 40px 50px;
        position: relative;
        text-align: center;
        margin: 0 auto;
    }

    &-name {
        position: absolute;
        top: -100px;
        left: -25%;
        background: white;
        padding: 50px;
        width: 50%;
        aspect-ratio: 1/1;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 5px 5px #CCC;
        
        @include media-breakpoint-down(md) {
            font-size: 16px;
            padding: 20px;
            left: calc(50% - 75px);
            top: -120px;
            width: 150px;
            box-shadow: 0 5px 5px #ccc;
        }
    }

    &-action-buttons {
        display: flex;
        justify-content: center;
        gap: 10px;

        a,
        button {
            display: inline-block;
            text-decoration: none;
            color: white;
            border-radius: 20px;
            padding: 5px 10px;
            border: 0;
            font-weight: bold;
            background: black;
            
            &.profile-info-delete {
                background: #C33
            }
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }
}