@import '../bootstrap-utils';

.honors {
    overflow: hidden;
    position: relative;
    padding-top: 100px;

    &-background {
        position: absolute;
        height: 140%;
        width: 100%;
        // opacity: 0.45;

         video {
             height: 100%;
             position: absolute;
             left: 50%;
             transform: translateX(-50%) translateY(-12%);
         }

     }

     &-top-heading {
        position: relative;
        background: white;
        text-align: center;
        border-radius: 0 0 50% 50%;
        aspect-ratio: 1/1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: -340px auto 0;
        width: 500px;
        padding: 0 80px 100px;

        h1 {
            margin-bottom: 30px;
            font-weight: bold;
        }

        @include media-breakpoint-down(md) {
            margin: -300px auto 0;
            width: 450px;
            padding: 0 80px 60px;
        }
        @include media-breakpoint-down(sm) {
            margin-top: calc(-75% + 25px);
            width: 100%;
            padding: 10% 15%;
        }
        @media (max-width: 364px) {
            padding: 30% 15%;

            h1 {
                margin-bottom: 10px;
            }
        }
     }
}

.add-new-honor {
    &-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        text-align: center;
        align-items: center;
        margin: 80px 0 20px;

        .document-page-title {
            display: flex;
            justify-content: center;
        }

        @include media-breakpoint-down(md) {
            display: block;
        }

        .column {
            &:last-child {
                text-align: right;
                @include media-breakpoint-down(md) {
                    text-align: center;
                }
            }
        }

        h2 {
            color: black;
            font-weight: bold;
            font-size: 2.5rem;
            font-weight: 800;

            background-color: white;
            border-radius: 40px;
            padding: 0 25px;
        }

        button {
            color: white;
            background: black;
            border-radius: 20px;
            border: 0;
            font-weight: bold;
            padding: 4px 28px;
        }
    }
}

.main-section{
    width: 95%;
    margin: 0 auto;
}

// CKEDitor fixes
.ck.ck-balloon-panel {
    z-index: 9999 !important;
}


