@import './bootstrap-utils';

/*
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    * {
        margin: 0;
        width: 450px;
        max-width: 100%;
    }
*/

.subscriptions-page-overlay {
    padding: 50px;

    h1, h2, .back-home-button {
        display: inline-block;
        border-radius: 50px;
        box-shadow: 0 5px 10px #CCC;
        padding: 15px 40px;
        margin: 20px auto;
        font-weight: bold;
        font-size: 36px;
        width: 450px;
        max-width: 100%;
        display: block;
    }

    h1 {
        background: black;
        color: white;
    }
    
    h2 {
        background: white;
        color: black;
    }
    
    @include media-breakpoint-down(lg) {
        padding: 20px;

        h1, h2 {
            padding: 10px 20px
        }
    }
}

.subscription-card {
    display: grid;
    grid-template-columns: 33% 45% 22%;
    max-width: 1200px;
    margin: 30px auto;
    box-shadow: -5px 5px 10px #CCC;
    border-radius: 25px;

    > * {
        padding: 30px;
        box-sizing: content-box;
        font-size: 15px;
        line-height: 32px;
        height: 32px;
        border-radius: 25px;
        margin-left: -50px;
        margin-bottom: 0;

        @include media-breakpoint-up(xl) {
            font-size: 19px;
        }
        @include media-breakpoint-down(md) {
            font-size: 21px;
        }
    }

    :nth-child(1) {
        background: white;
        margin-left: 0;
        padding-right: 60px;
    }
    :nth-child(2) {
        background: #EEE;
        padding-right: 60px;
    }
    :nth-child(3) {
        background: #39E;
        color: white;
        border: none;
        box-shadow: -5px 0 5px #CCC;
    }
    
    &.future-subscription {
        :nth-child(1) {
            background: #39E;
        }
    }

    @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr;
        box-shadow: 0 0 10px #CCC;
        border: 1px solid white;

        > * {
            margin: 0;
            box-shadow: none;
            height: auto;
            padding: 30px !important;
        }
        
        :nth-child(1) {
            border-radius: 25px 25px 0 0;
        }
        :nth-child(2) {
            border-radius: 0;
        }
        :nth-child(3) {
            border-radius: 0 0 25px 25px;
        }
    }
}