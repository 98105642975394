.honor-view {
    &__file-wrapper {
        padding: 40px;
    }

    &-item {
        background: black;
        color: white;
        padding-bottom: 20px;

        main {
            max-width: 1049px;
            margin: 20px auto;
            display: flex;
            flex-direction: column;
        }

        &__banner {
            width: 100%;
            height: 50vh;
            background-size: cover;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-color: white;
        }

        &__content {
            text-align: justify;
            padding: 10px 40px;
            max-width: 1200px;
            margin: 0 auto;

            div {
                max-width: none !important;
            }
        }
    }
}
