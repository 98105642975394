@import './bootstrap-utils';

.recurring-checkout-form {
    .result-container{
        background: #FFC9F6;
        padding: 15px 20px;
        border-radius: 5px;
        color: white
    }

    .ResultTitle {
        font-weight: bold;
    }

    .ResetButton {
        cursor: pointer;
        background: gray;
        padding: 10px;
        border-radius: 4px;

        svg {
            transition: .3s transform;
        }

        &:hover svg {
            transform: rotate(180deg);
        }
    }

    .recurring-submit {
        font-size: 25px;
        color:$white;
        font-weight: bold;
        border-radius: 20px;
        box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.3);
        transition: all 0.3s ease-in-out; 
        &:hover{
            transform: translateY(-3px);
        }
    }

    .recurring-submit-button-instructions{
        margin-bottom: 10px;
        margin-top: 10px;
        text-align: center;
    }

    .text-center{
        font-weight: bold;
    }

    .box-with-shadow {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding:16px;
        margin-bottom: 30px;
    }

    .card-field {
        border: $input-border-width solid $input-border-color;
        padding: $input-padding-y $input-padding-x;
    }

}