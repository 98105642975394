.event-card {
    border-radius: 20px;
    display: block;
    overflow: hidden;
    margin-bottom: 30px;
    color: #000;
    text-decoration: none;
    position: relative;
    background-color: white;

    &-link {
        text-decoration: none;
    }

    &-infos {

        &-image {
            height: 220px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-top: 10px;
        }

        &-inner {
            position: relative;
            border: 6px solid black;
            background-color: white;
            border-radius: 100px;
            text-align: center;
            position: relative;
            border-bottom-width: 10px;
            padding: 15px 35px;

            &__wrapper {
                background: black;
                border-radius: 80px 80px 0 0;
            }
        }
    }

    &-title {
        font-size: 18px;
        color: black;
        margin-bottom: 30px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &-date {
        font-size: 20px;
        color: black;
        font-weight: 800;
        margin: 0;
    }

    &-controls {
        display:none;
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        z-index: 2;

        .event-card:hover & {
            display:flex;
            padding-top: 5px;
            justify-content: space-between;
        }
    }

    &-control {
        border: 0;
        background-color: transparent;
        border-radius: 100px;
        width: 50px;    
        transition: all 0.3s ease-in-out; 
        opacity: 50%;
        
        &:hover{
            opacity: 100%;
            transform: translateY(-2px);
            transform: scale(1.2);
        }

        img {
            max-width: 100%;
        }
    }
}