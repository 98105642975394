@import './bootstrap-utils';

.giving-modal {
    .modal {
        &-dialog {
            width: 1000px;
            max-width: 90%;
        }
        &-header {
            background-color: $primary;
            color: $white;
            justify-content: center;
            position:relative;

            /*.btn-close {
                margin:0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 20px;
                width: 20px;
                height: 20px;
                background-size: cover;
            }*/

            .btn{
                .btn-primary{
                    background-color: brown;
                }
            }
            
            .submitBtn{   
                &:hover{
                    transition: all 0.3s ease-in-out; 
                    transform: translateY(-3px);
                }
            }

        }

        &-body, &-footer {
            @include media-breakpoint-up(md) {
                padding-left: 100px;
                padding-right: 100px;
            }
        }

        &-footer {
            padding-bottom: 50px;
        }
    }

    .currency-display {
        background-color: #D9D9D9;
        text-align: center;
        padding: 5px 10px;
        font-weight: normal;
        font-size: 16px;
        border-radius: 3px;
    }

    .giving-buttons {
        button {
            border: 0;
            transition: .3s;
            &:hover {
                opacity: .7;
                transform: scale(1.1);
            }
        }
        .btn-primary {
            background: #4E372B;
        }
        .btn-secondary {
            background: #929292;
        }
    }

    .giving-dropdown {
        background-color: #929292;
        color: white;
    }
}

.giving-modal-one-time-pay-page {
    .StripeElement {
        margin-bottom: 30px;
    }

    .checkout-form-buttons {
        display: flex;
        gap: 10px;
        margin-bottom: 15px;

        button {
            padding: 10px 20px;
            border: 0;
            box-shadow: 0 10px 5px #999;
            color: white;
            border-radius: 5px;
            font-size: 22px;
            font-weight: bold;
            
            &.checkout-confirm {
                background: #FFC9F6;
                
            }
            &.checkout-cancel {
                background: #87E0FC;
            }
        }

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }

    .billing-details-paying {
        padding: 10px 20px;
        box-shadow: 0 10px 5px #999;
        color: white;
        border-radius: 5px;
        font-weight: bold;
        background: #868686;
        margin-bottom: 30px;
        display: block;
        max-width: 435px;
    }
}