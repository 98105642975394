html.bounce-proof {
  background-color: black;

  &, body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
  }
  
  /*.header .navbar {
    background: black;
  }*/

  #root {
    height: 100vh;
    overflow: auto;
    position: relative;
    z-index: 1;

    -webkit-overflow-scrolling: touch;
    max-width: 100%;
    overflow-x: hidden;
    overscroll-behavior: none;
  }
}

