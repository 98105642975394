@import '../bootstrap-utils';

.back-home{
    text-align: center;
    margin-bottom: 40px;

    &-button {

        display: inline-block;
        text-decoration: none;
        padding: 20px 40px;
        background-color: black;
        font-weight: bold;
        color: white;
        border-radius: 100px;
        font-size: 30px;

    }

    &-success{
        text-align: center;
        display: inline-block;
        text-decoration: none;
        padding: 20px 40px;
        background-color: black;
        font-weight: bold;
        color: white;
        border-radius: 100px;
        font-size: 30px;
        border: 2px solid transparent;
        transition: all 0.3s ease-in-out; 

        &:hover{
            color: white;
            border: 2px solid #7DB9FF;
            transform: scale(1.1);
            box-shadow: 0 20px 40px rgb(0, 0, 0);
        }
    }

}