@import './bootstrap-utils';

.password-recovery-overlay {
    background-color: white;
    padding: 20px;
    min-height: calc(100vh - 270px);

    h1 {
        text-align: center;
        color: white;
        font-weight: 800;
    }
    
    @media (max-width: 420px) {
        .go-back-buttons {
            flex-direction: column;
        }
    }

    @include media-breakpoint-down(sm) {
        .back-from-password-recovery,
        .back-home-button-bordered {
            font-size: 1.2rem;
            text-align: center;
            padding: 20px;
        }
    }
}

.email-input {
    &:invalid + button {
        background: red;
    }

    &::placeholder{
        color: white;
    }
}

.back-from-password-recovery{
    box-shadow: none !important;
}

.recover-password-form {
    padding: 100px 0;
    margin: 0 auto;
    max-width: 700px;

    input, button {
        color: white;
    }
    
    @include media-breakpoint-down(sm) {
        padding: 50px 0;
    }
}

.rounded-input__button {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    
    input, button {
        border-radius: 100px;
        padding: 10px 25px;
        font-size: 1.5em;
        border: none;
        @include media-breakpoint-down(md) {
            font-size: 1em;
        }
    }
    
    input {
        position: relative;
        background: #CCC;
    }
    
    button {
        background: #6CF;
        padding-right: 40px;
        padding-left: 80px;
        margin-left: -60px;
        
        @include media-breakpoint-down(sm) {
            margin-left: 0;
            padding: 10px 20px;
        }
    }
    
    @include media-breakpoint-down(sm) {
        flex-direction: column;
        gap: 15px;
    }
}