@import './bootstrap-utils';

.video-container {
    .form-container {
        max-width: 460px;
        margin: 140px auto;
        min-height: 80px;

        
    }

    form.register-form {
        box-shadow: 0 20px 40px rgb(0, 0, 0);

        h3 {
            color: white;
            margin-bottom: 20px;
            border-bottom: 1px solid white;
            padding-bottom: 20px;
        }

        input {
            margin-bottom: 10px;
        }

        .sign-up-buttons {

            @include media-breakpoint-down(md) {
                flex-direction: column;
                gap: 20px;
            }
           
        }

        
    }
    
    footer {
        background: rgba(0,0,0,.7);
        color: white;
        padding: 30px 50px;
        max-width: 600px;
        margin: 30px auto;
        font-weight: bold;
        border-radius: 10px;
        
        

        a {
            color: #7DB9FF;
            text-decoration: none;
        }

        .forgot-password {
            background: white;
            display: inline-block;
            padding: 5px 10px;
            border-radius: 20px;
            margin-top: 20px;
        }
    }

    
}
