@import './bootstrap-utils';

.more-options-menu {
        flex:1 0 100%;
        margin-bottom:10px;

        
        @include media-breakpoint-up(md) {
        margin-bottom: 0;
        margin-left: 12px;
        padding-right: 20px;
        flex:initial;
    }
    &-icon {
        width: 30px
    }

    &-button {
        background-color: transparent;
        padding: 0;
        border: 0;
        transition: all 0.3s ease-in-out;

        &:after {
            content: none;
        }

        &:hover{
            background-color: transparent;
            transform: scale(1.1);
        }
    }

    .dropdown-item {
        color: rgb(49, 43, 61);
        transition: all 0.3s ease-in-out;

        &:hover {
            transform: translateY(-1px) scale(1.1);
        }
    }
}