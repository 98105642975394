@import './bootstrap-utils';

.modal-buttons-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 25px;

}

.modal-dialog {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.button-custom,
.modal-button {
    background: black;
    color: white;
    border-radius: 50px;
    padding: 5px 30px;
    font-size: 2rem;
    border: 0;
    text-decoration: none;
    text-align: center;
    
    &.button-small {
        font-size: 1rem;
        padding: 5px 10px;
    }

}

.button-center {
    margin: 0 auto;
}

.dark,
.dark-buttons {
    gap: 30px;

    .button-custom,
    .modal-button {
        background: white;
        color: black;
        font-weight: 800;
        padding: 5px 50px;

        &.button-small {
            padding: 5px 20px;
        }
    }
}

.modal {
    &-header {
        &__dark {
            background: $primary;
        }
        &__centered {
            justify-content: center;
        }
        &--close {
            position: absolute;
            top: 15px;
            right: 15px;
        }
    }
    &-title {
        &__with-logo {
            display: flex;
            gap: 15px;
            flex-direction: column;
            align-items: center;
            color: white;
            font-family: "Inter", sans-serif;
            font-variant: small-caps;
        }
    }

    &-content {
        background: #D9D9D9;
        border: 0;
    }

    &-light &-content {
        background: white;
    }

    &-delete {
        margin-top: 20%;
        
        .modal {
            &-dialog {
                max-width: 440px;
            }
            
            &-content {
                background: black;
                color: white;
                border-radius: 40px;
            }

            &-header,
            &-footer {
                border: 0;
            }
        }
    }
}