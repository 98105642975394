@import './variables.scss';
@import '../node_modules/bootstrap/scss/bootstrap';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


form.form-default {
  background: #DDD;
  padding: 20px;
  border-radius: 5px;
  margin: 50px 0;
  color: black;
  box-shadow: 0 20px 40px rgb(69 100 115);
  
  ::placeholder {
    color: #D9D9D9;
  }

  input[type=password]{
    &::placeholder{
      color: #D9D9D9;
    }
  }

  h1 {
    margin-bottom: 20px;
    border-bottom: 1px solid white;
    padding-bottom: 20px;
    color: white;
  }

  label, input[type=text],
  label, input[type=password],
  input[type=datetime-local],
  textarea {
    display: block;
  }

  label {
    font-weight: bold;
  }

  input[type=text],
  input[type=password],
  input[type=datetime-local],
  input[type=email],
  textarea {
    width: 100%;
    border-radius: 5px;
    margin: 10px 0 35px;
    border: 1px solid white;
    background-color: #FCFCFC;
    box-shadow: 0 5px 5px #CCC;
    font-size: 21px;
    padding: 5px 10px;

    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }

  [type=radio] {
      margin-right: 5px;
  }

  textarea {
    background-attachment: local;
    background-image:
        linear-gradient(to right, white 10px, transparent 10px),
        linear-gradient(to left, white 10px, transparent 10px),
        repeating-linear-gradient(white, white 30px, #ccc 30px, #ccc 31px, white 31px);
    line-height: 31px;
    text-indent: 20px;
    padding: 3px 15px;
    border-radius: 20px;
  }   
}

.two-column-grid {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}

@include media-breakpoint-down(md) {
  .md-one-column-grid {
    grid-template-columns: 1fr;
  }
}

.modal-footer-buttons {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;

  button {
    border-radius: 30px;
    padding: 10px;
    border: none;
    font-weight: bold;
    font-size: 22px;
    box-shadow: 0 5px 5px #999;
    flex-grow: 1;
  }

  button.cancel {
    background: #BBB;
    color: white;
  }

  button.submit {
    background: white;
    color: #48E;
  }
}

form.form-simple {
  box-shadow: none;
  padding: 0;
  background: none;
  margin: 0;

  input {
    margin-bottom: 15px !important;
  }
}
