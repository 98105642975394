@import '../bootstrap-utils';

.broadcasts-background {
    position: absolute;
    height: 150%;
    width: 100%;

     video {
         height: 100%;
         position: absolute;
         left: 50%;
         transform: translateX(-50%) translateY(-12%);
    }
}

.broadcasts-page {


    .broadcasts-page-header {
        position: relative;
        overflow: hidden;
        color: #fff;
        padding-top: 50px;
        @include media-breakpoint-up(md) {
            padding-top: 80px;
        }

        .container {
            position: relative;
        }
    }

    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 25px;
        text-align: center;

        @include media-breakpoint-up(md) {
            font-size: 35px;
            margin-bottom: 40px;
            text-align: center;
        }
    }

    .subtitle {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 15px;
        text-align: center;

        @include media-breakpoint-up(md) {
            font-size: 35px;
            margin-bottom: 25px;
            text-align: center;
        }
    }

    .last-video {
        
        &-title {
            font-size: 25px;
            margin: 20px 0;
            text-align: center;

            @include media-breakpoint-up(md) {
                font-size: 50px;
                text-align: left;
                margin: 30px 0;
            }
        }

        &-date {
            font-size: 16px;
            text-align: center;

            @include media-breakpoint-up(md) {
                font-size: 18px;
                text-align: left;
            }
        }
    }

    .broadcasts-videos {
        padding: 40px 0;
        margin-left: 0px;
        @include media-breakpoint-up(md) {
            padding: 30px 0;
        }

        &-previous{
            padding: 0;
        }
    }
}
.broadcast-video-item {
    text-align: left;
    background: #E3E3E3;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    padding: 27px 20px;
    margin-bottom: 20px;
    font-weight: bold;

    &-title {
        font-weight: bold;
        margin-top:10px
    }

    &-date {
        margin: 0;
        font-size: 14px;
    }
}

.subheader-banner{
    background-color: black;
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    padding: 20px 15px;
    margin:0;

    @include media-breakpoint-up(md) {
        font-size: 40px;
    }
}

.broadcasts-back-home {
    text-align: center;
    margin-top: 0;
    margin-bottom: 40px;

    &-button {
        display: inline-block;
        text-decoration: none;
        padding: 20px 40px;
        background-color: black;
        font-weight: bold;
        color: white;
        border-radius: 100px;
        font-size: 30px;
    }
}

