
.ql-align-right {
	text-align: right;
}
.ql-align-center {
	text-align: center;
}
.ql-align-left {
	text-align: left;
}

.ql-size-small {
	font-size: 0.75em;
}
.ql-size-medium {
	
}
.ql-size-large {
	font-size: 1.5em;
}
.ql-size-huge {
	font-size: 2em;
}