@import './bootstrap-utils';

.absolute-video {
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;

    video {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.prayer-requests-page {
    position: relative;
    padding: 80px 0;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        padding: 0;
    }

    .prayer-radio-button-title{
        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 16px;
        margin-top: 16px;
        text-align: center;
    }

    video {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-top: 1px solid black;
        border-bottom: 1px solid black;

        top: 10px;
        height: calc(100% - 10px);
        //transform: translateY(-1px);

        &.scrolled {
            top: 0;
            height: 100%;
            transform: none;
        }

        &.at-footer {
            height: 99%;
        }
    }

    .name-date-list {
        li {
            display: block;
        }

        @include media-breakpoint-up(md) {
            display: flex;
        }
    }

    main {
        position: relative;
        max-width: 800px;
        margin: 0 auto;
        background: rgba(0,0,0,.7);
        padding: 30px 100px;
        border-radius: 10px;
        color: white;

        .submit-button {
            display: inline-block;
            text-decoration: none;
            padding: 10px 40px;
            background-color: black;
            font-weight: bold;
            color: white;
            border-radius: 100px;
            font-size: 18px;
            border: 2px solid black;
            transition: 0.5s;

            &:hover {
                border: 2px solid #69F;
                color: #69F;
                transform: scale(1.1);
                box-shadow: 0 20px 40px rgb(0, 0, 0);
            }

        }

        h1 {
            margin-bottom: 20px;
            border-bottom: 1px solid white;
            padding-bottom: 20px;
        }

        @include media-breakpoint-down(md) {
            padding: 100px 15px 50px;
            border-radius: 0;
        }
    }
}